import React, { useState, useContext, useEffect, useRef } from "react";
import { UserContext } from "../UserContext"; // import UserContext
import { AuthContext } from "../AuthProvider";
import { Link } from "react-router-dom";
import {
  FaCaretDown,
  FaUser,
  FaGraduationCap,
  FaYoutube,
  FaHashtag,
  FaCompass,
  FaRobot,
  FaVideo,
  FaBookOpen,
  FaBlog,
  FaPuzzlePiece,
  FaMicrophone,
  FaImage,
  FaPaintBrush,
  FaQrcode,
  FaQuestionCircle,
  FaCrown,
  FaSignOutAlt,
} from "react-icons/fa"; // Import the FaGraduationCap icon from react-icons
import { BsLightningFill } from "react-icons/bs";
import {
  MdOutlineAutoAwesome,
  MdOutlinePlayCircleFilled,
} from "react-icons/md"; // Import video play icon
import { KimaviBrand } from "./KimaviBrand";
import { toast } from "react-toastify";

function Navbar({
  onLoginClick,
  handleSignOut,
  resetSidebarTab,
  autoShowLogin,
}) {
  const [dropdownStates, setDropdownStates] = useState({
    appsDropdownOpen: false,
    userDropdownOpen: false,
    exploreDropdownOpen: false,
  });

  const { user, isPaid, idToken, isAuthChecking } = useContext(AuthContext);
  const appsDropdownRef = useRef(null);
  const userDropdownRef = useRef(null);
  const exploreDropdownRef = useRef(null);

  const [forceUpdate, setForceUpdate] = useState(false);
  const [serverDate, setServerDate] = useState(null);
  const [localCacheDate, setLocalCacheDate] = useState(new Date());
  const [toastFired, setToastFired] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    if (isAuthChecking) {
      // Authentication is still loading; do nothing yet
      return;
    }

    if (autoShowLogin && !user && !toastFired) {
      toast("Please Sign In.");
      window.scrollTo(0, 0);
      onLoginClick();
      setToastFired(true);
    }
  }, [autoShowLogin, user, onLoginClick, isAuthChecking, toastFired]);

  const appsLinks = [
    { name: "Create Agent", path: "/create-chatbot" },
    { name: "Create Video", path: "/ai" },
    { name: "Create Guide", path: "/guide" },
    {
      name: "Install Extension",
      path: "https://chromewebstore.google.com/detail/kimavi-create-how-to-vide/kcleenakjlelbmchbkfpgoldgglhdpne?hl=en&authuser=0&pli=1",
    },
    { name: "Blog to Video", path: "/blog-to-video" },
    { name: "Create Audio", path: "/audio" },
    { name: "Create Thumbnail", path: "/tc" },
    { name: "Create Logo", path: "/logo" },
    { name: "Create Banner", path: "/banner" },
    { name: "Create QR Code", path: "/qr" },
    { name: "FAQs", path: "/faq" },
    { name: "Subscribe", path: "/subscribe" },
  ];

  const toggleDropdown = (dropdown) => {
    setDropdownStates((prevState) => ({
      ...prevState,
      [dropdown]: !prevState[dropdown],
    }));
  };

  const handleOutsideClick = (event, ref, dropdown) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setDropdownStates((prevState) => ({
        ...prevState,
        [dropdown]: false,
      }));
    }
  };

  useEffect(() => {
    const handleOutsideClicks = (event) => {
      handleOutsideClick(event, appsDropdownRef, "appsDropdownOpen");
      handleOutsideClick(event, userDropdownRef, "userDropdownOpen");
      handleOutsideClick(event, exploreDropdownRef, "exploreDropdownOpen");
    };

    if (Object.values(dropdownStates).some((isOpen) => isOpen)) {
      document.addEventListener("click", handleOutsideClicks);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClicks);
    };
  }, [dropdownStates]);

  const fetchServerDate = async () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const date = new Date();
        resolve({ date });
      }, 1000);
    });
  };

  useEffect(() => {
    const getServerDate = async () => {
      const response = await fetchServerDate();
      setServerDate(new Date(response.date));
    };
    getServerDate();
  }, []);

  useEffect(() => {
    if (forceUpdate || (serverDate && serverDate > localCacheDate)) {
      if (forceUpdate) {
        toast.success("Cache updated successfully");
      }
      setLocalCacheDate(serverDate || new Date());
      setForceUpdate(false);
    }
  }, [serverDate, forceUpdate]);

  const appIcons = {
    "Create Agent": FaRobot,
    "Create Video": FaVideo,
    "Create Guide": FaBookOpen,
    "Blog to Video": FaBlog,
    "Install Extension": FaPuzzlePiece,
    "Create Audio": FaMicrophone,
    "Create Thumbnail": FaImage,
    "Create Logo": FaPaintBrush,
    "Create Banner": FaPaintBrush,
    "Create QR Code": FaQrcode,
    FAQs: MdOutlineAutoAwesome,
    Subscribe: BsLightningFill,
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const getLogo = () => {
    const hostname = window.location.hostname;
    // console.log(hostname);
    if (hostname === "beta.kimavi.com" || hostname === "localhost") {
      return {
        src: "/images/auxo-logo.png",
        alt: "Auxo AI Logo",
      };
    }
    return {
      src: "/images/k-rect-logo.png",
      alt: "Kimavi Logo",
    };
  };

  return (
    <nav
      id="navbar"
      className={`fixed top-0 left-0 right-0 py-2 items-center z-50 transition-all duration-300 ${
        isScrolled
          ? "bg-gradient-to-r from-blue-800/95 to-purple-800/95 backdrop-blur-md shadow-lg"
          : "bg-gray-800"
      }`}
    >
      <div className="container px-3 md:px-3 flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <div className="flex items-center ">
            <Link
              to="/"
              onClick={(e) => {
                e.preventDefault();
                window.location.href = "/";
              }}
              className="flex items-center"
            >
              <img
                src={getLogo().src}
                alt={getLogo().alt}
                logo
                className="w-32 md:w-64 rounded-lg"
              />
            </Link>
          </div>
        </div>

        <div className="flex items-center space-x-2 md:space-x-4">
          {!user ? (
            <button
              onClick={onLoginClick}
              className="px-4 py-2 rounded-xl bg-gradient-to-r from-blue-500 to-purple-600 text-white font-bold hover:shadow-blue-500/25 transition-all duration-300 inline-flex items-center justify-center w-[80px] md:w-[120px]"
            >
              <FaUser className="w-4 h-4 mr-2" />
              <span className="hidden md:inline">Login</span>
            </button>
          ) : (
            <div
              ref={userDropdownRef}
              className="relative inline-flex items-center"
            >
              <span
                className="px-4 py-2 rounded-xl bg-gradient-to-r from-blue-500 to-purple-600 text-white font-bold hover:shadow-blue-500/25 transition-all duration-300 inline-flex items-center justify-center w-[80px] md:w-[120px]"
                onClick={() => toggleDropdown("userDropdownOpen")}
              >
                <FaUser className="w-4 h-4 md:mr-2" />
                <span className="hidden md:inline">My</span>
              </span>
              {dropdownStates.userDropdownOpen && (
                <ul className="absolute z-10 w-48 bg-gray-900/95 backdrop-blur-md border border-gray-700 text-white rounded-lg shadow-lg top-full mt-1 overflow-hidden">
                  <li>
                    <Link
                      to="/create-chatbot"
                      className="flex items-center gap-3 w-full px-4 py-3 text-sm hover:bg-white/10 transition-colors"
                    >
                      <FaRobot className="w-4 h-4 text-blue-400" />
                      <span>My Agents</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/myvideo?tab=videos"
                      className="flex items-center gap-3 w-full px-4 py-3 text-sm hover:bg-white/10 transition-colors"
                    >
                      <FaVideo className="w-4 h-4 text-purple-400" />
                      <span>My Videos</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/myvideo?tab=guides"
                      className="flex items-center gap-3 w-full px-4 py-3 text-sm hover:bg-white/10 transition-colors"
                    >
                      <FaBookOpen className="w-4 h-4 text-green-400" />
                      <span>My Guides</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/myvideo?tab=blogs"
                      className="flex items-center gap-3 w-full px-4 py-3 text-sm hover:bg-white/10 transition-colors"
                    >
                      <FaBlog className="w-4 h-4 text-pink-400" />
                      <span>My Blogs</span>
                    </Link>
                  </li>
                  <li>
                    <button
                      onClick={() => {
                        handleSignOut();
                        toggleDropdown("userDropdownOpen");
                      }}
                      className="flex items-center gap-3 w-full px-4 py-3 text-sm hover:bg-white/10 transition-colors text-red-400"
                    >
                      <FaSignOutAlt className="w-4 h-4" />
                      <span>Logout</span>
                    </button>
                  </li>
                </ul>
              )}
            </div>
          )}

          <div
            ref={exploreDropdownRef}
            className="relative inline-flex items-center"
          >
            <span
              className="px-4 py-2 rounded-xl bg-blue-500/20 hover:bg-gray-700/50 transition-all duration-300 cursor-pointer text-blue-400 hover:text-gray-300 border border-blue-500/30 hover:border-gray-600/30 inline-flex items-center justify-center w-[80px] md:w-[120px]"
              onClick={() => toggleDropdown("exploreDropdownOpen")}
            >
              <FaCompass className="w-4 h-4 md:mr-2" />
              <span className="hidden md:inline">Explore</span>
            </span>
            {dropdownStates.exploreDropdownOpen && (
              <ul className="absolute z-10 w-48 bg-gray-900/95 backdrop-blur-md border border-gray-700 text-white rounded-lg shadow-lg top-full mt-1 overflow-hidden">
                <li>
                  <Link
                    to="/tags"
                    onClick={() => toggleDropdown("exploreDropdownOpen")}
                    className="flex items-center gap-3 w-full px-4 py-3 text-sm hover:bg-white/10 transition-colors"
                  >
                    <FaBookOpen className="w-4 h-4 text-blue-400" />
                    <span>Guides</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/college"
                    onClick={() => toggleDropdown("exploreDropdownOpen")}
                    className="flex items-center gap-3 w-full px-4 py-3 text-sm hover:bg-white/10 transition-colors"
                  >
                    <FaYoutube className="w-4 h-4 text-red-400" />
                    <span>Videos</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/tools"
                    onClick={() => toggleDropdown("exploreDropdownOpen")}
                    className="flex items-center gap-3 w-full px-4 py-3 text-sm hover:bg-white/10 transition-colors"
                  >
                    <BsLightningFill className="w-4 h-4 text-yellow-400" />
                    <span>Tools</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/training"
                    onClick={() => toggleDropdown("exploreDropdownOpen")}
                    className="flex items-center gap-3 w-full px-4 py-3 text-sm hover:bg-white/10 transition-colors"
                  >
                    <FaGraduationCap className="w-4 h-4 text-green-400" />
                    <span>Training</span>
                  </Link>
                </li>
              </ul>
            )}
          </div>

          <div
            ref={appsDropdownRef}
            className="relative inline-flex items-center"
          >
            <span
              className="px-4 py-2 rounded-xl bg-blue-500/20 hover:bg-gray-700/50 transition-all duration-300 cursor-pointer text-blue-400 hover:text-gray-300 border border-blue-500/30 hover:border-gray-600/30 inline-flex items-center justify-center w-[80px] md:w-[120px]"
              onClick={() => toggleDropdown("appsDropdownOpen")}
            >
              <BsLightningFill className="w-4 h-4 md:mr-2" />
              <span className="hidden md:inline">Apps</span>
            </span>
            {dropdownStates.appsDropdownOpen && (
              <ul className="absolute z-10 w-48 bg-gray-900/95 backdrop-blur-md border border-gray-700 text-white rounded-lg shadow-lg top-full mt-1 md:right-0 right-auto -left-[6.5rem] overflow-hidden">
                {appsLinks.map((app, index) => {
                  const IconComponent = appIcons[app.name];
                  return (
                    <li key={index}>
                      <Link
                        to={app.path}
                        onClick={() => toggleDropdown("appsDropdownOpen")}
                        className={`flex items-center gap-3 w-full px-4 py-3 text-sm hover:bg-white/10 transition-colors
                          ${
                            app.name === "Subscribe" || app.name === "FAQs"
                              ? "inline-flex items-center gap-2 bg-gradient-to-r from-blue-400 to-purple-500 text-transparent bg-clip-text font-semibold"
                              : ""
                          }`}
                      >
                        {IconComponent && (
                          <IconComponent
                            className={`w-4 h-4 ${
                              app.name === "Subscribe"
                                ? "text-purple-400"
                                : app.name === "FAQs"
                                ? "text-blue-400"
                                : "text-blue-400"
                            }`}
                          />
                        )}
                        <span>{app.name}</span>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
