// ChatBot.js

/*
  Filename: ChatBot.js
  Below is the full code with minimal changes to ensure that 
  the input bar stays pinned to the bottom when rendering inside an iframe.

  EXPLANATION OF CHANGES (search for the "CHANGED" comments):
  1) In the `if (iframeMode)` block, we replaced `className="w-full h-full flex flex-col"`
     with `className="w-full h-screen flex flex-col"` and wrapped `chatContent` 
     in a scrollable `.flex-1.overflow-y-auto.relative` container.

  2) Inside `chatContent`, we removed the extra "flex-1" from the top-level 
     messages container to avoid nested scrolling conflicts (so `className="overflow-y-auto p-4"` 
     instead of `flex-1 overflow-y-auto p-4`).

  These changes ensure that the iframe has a set height (h-screen within its own 
  HTML context) and the messages area properly scrolls, allowing the input bar 
  to remain at the bottom (sticky bottom-0).
*/

import ReactPlayer from "react-player";
import knownQA from "./knownQA.js";
import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { NODE_ENV } from "./constants/config";
import {
  FaRobot,
  FaUser,
  FaTimes,
  FaCommentDots,
  FaRegCopy,
  FaArrowDown,
  FaSyncAlt, // refresh icon
} from "react-icons/fa";
import ReactMarkdown from "react-markdown";
import { v4 as uuidv4 } from "uuid"; // For generating unique session IDs
import { useNavigate } from "react-router-dom";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { motion, AnimatePresence } from "framer-motion";

// -----------------------------------------------------------------------------
// Two required questions (the default 2):
// -----------------------------------------------------------------------------
const demoGuides = "Give me a demo of Guides";
const demoVideo = "Give me a demo of Video";

// 1) Demo of In-Chat Guides: iFrame
if (!knownQA.some((qa) => qa.question === demoGuides)) {
  knownQA.push({
    question: demoGuides,
    answer: `<iframe src="https://www.kimavi.com/view?id=recording_1730710552564_b673f7908a9f9&source=server&uid=wVdZ4zMt1PYD6mmNflk6ZApiZEe2&embed=true" width="100%" height="960" frameborder="0" allowfullscreen></iframe>`,
  });
}

// 2) Demo of In-Chat Video: YouTube Link
if (!knownQA.some((qa) => qa.question === demoVideo)) {
  knownQA.push({
    question: demoVideo,
    answer: "https://www.youtube.com/watch?v=2GIumvSUO_Q",
  });
}
// Default logo URL
const defaultBotLogo = "/k-rect-logo.png";

// Fisher-Yates Shuffle (optional if you want random picks)
const shuffleArray = (array) => {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
};

function handleNavigateFallback(action, text) {
  // If the server did not provide #action=navigate#
  // but the text includes navigate("...")
  // we do a fallback so we can still open a new window
  let finalAction = action;
  let finalText = text;

  // If the text includes "navigate("
  if (!finalAction || finalAction === "doNothing") {
    if (finalText.includes('navigate("')) {
      finalAction = "navigate";
    }
  }

  // If we ended up with navigate, parse out the route
  if (finalAction === "navigate") {
    const routeMatch = finalText.match(/navigate\("([^"]+)"\)/);
    if (routeMatch && routeMatch[1]) {
      // Open in new tab
      window.open(routeMatch[1], "_blank");
      // Optionally clear finalText so user doesn't see
      // the raw navigate(...) text
      finalText = "";
    }
  }

  return { finalAction, finalText };
}

// -------------------------------------------
// Helper to parse out #action=XYZ# from text
// -------------------------------------------
function parseActionTag(botMessage) {
  // We'll store the entire message in sanitizedMessage
  // and remove *only* #action=xxx# matches here
  const allActionsRegex = /#action=([^#]+)#/gi;
  let allMatches = [...botMessage.matchAll(allActionsRegex)];

  let action = "doNothing";
  let sanitizedMessage = botMessage;

  if (allMatches.length > 0) {
    // Typically, only the first action is relevant
    action = allMatches[0][1].trim(); // e.g. 'navigate', 'video', etc.
    // Remove *all* occurrences of #action=xxx#
    sanitizedMessage = sanitizedMessage.replace(allActionsRegex, "").trim();
  }

  return { action, sanitizedMessage };
}

// -----------------------------------------------------------------------------
// NOTE: We now remove *all* text inside #...# before rendering to the client
// -----------------------------------------------------------------------------
export function parseServerMedia(botMessage) {
  // 1) Strip out #action=xxx#
  const { action, sanitizedMessage } = parseActionTag(botMessage);

  // 1.1) Remove *any* text that is inside #...# (e.g. #foo=bar#).
  // This ensures all #...# tags (besides #action=xxx#) are also removed.
  const fullySanitizedMessage = sanitizedMessage.replace(/#[^#]*#/g, "").trim();

  // 2) Attempt to find an iFrame
  const iframeRegex = /<iframe[\s\S]*?<\/iframe>/i;
  const iframeMatch = fullySanitizedMessage.match(iframeRegex);
  if (iframeMatch) {
    return {
      action,
      finalText: iframeMatch[0].trim(),
    };
  }

  // 3) Attempt to find a YouTube link
  const youtubeRegex = /(https?:\/\/(www\.)?youtube\.com\/[^\s]+)/i;
  const youtubeMatch = fullySanitizedMessage.match(youtubeRegex);
  if (youtubeMatch) {
    return {
      action,
      finalText: youtubeMatch[0].trim(),
    };
  }

  // 4) If no iFrame or YouTube link, just return all sanitized text
  return {
    action,
    finalText: fullySanitizedMessage,
  };
}

const BotLogo = () => (
  <div className="relative w-8 h-8">
    {/* Main bot logo with rocking animation */}
    <motion.div
      initial={{ rotate: -10 }}
      animate={{ rotate: 10 }}
      transition={{
        repeat: Infinity,
        repeatType: "reverse",
        duration: 2,
        ease: "easeInOut",
      }}
      className="w-full h-full absolute -top-1 -right-1"
    >
      <div
        className="w-7 h-7 rounded-full p-0.5"
        style={{
          background: "white",
          boxShadow:
            "0 0 0 2px white, 0 0 0 3px #e2e8f0, 0 0 10px 2px rgba(0, 0, 0, 0.1)",
        }}
      >
        <img
          src="/klogo.png"
          alt="Bot Logo"
          className="w-full h-full rounded-full"
          style={{
            objectFit: "cover",
          }}
        />
      </div>
    </motion.div>

    {/* Pulsing notification dot */}
    <motion.div
      className="absolute -top-1 -right-1 w-2 h-2 bg-red-500 rounded-full"
      animate={{
        scale: [1, 1.2, 1],
        opacity: [0.7, 1, 0.7],
      }}
      transition={{
        duration: 2,
        repeat: Infinity,
        ease: "easeInOut",
      }}
    />
  </div>
);

// Add these welcome message variations near the top of the file
const welcomeMessages = [
  {
    greeting: "Hello! I'm here to help you 👋",
    capabilities: [
      "Answer questions with **ChatGPT**",
      "Show videos - **ShowMe**",
      "Play guides - **GuideMe**",
    ],
    closing: "Just ask me anything 😊",
  },
  {
    greeting: "Hi there! Ready to assist you 🌟",
    capabilities: [
      "Chat with **AI** for answers",
      "Watch **Video** tutorials",
      "Follow interactive **Guides**",
    ],
    closing: "What can I help you with? ✨",
  },
  {
    greeting: "Welcome! Let's get started 🚀",
    capabilities: [
      "Get answers with **AI Assistant**",
      "Learn through **Video Content**",
      "Experience **Interactive Guides**",
    ],
    closing: "How can I assist you today? 💡",
  },
];

// Add the gradient combinations
const gradientCombinations = [
  {
    // Dark Blue to Black
    background: "linear-gradient(135deg, #1E3A8A 0%, #000000 100%)",
  },
  {
    // Violet
    background: "linear-gradient(135deg, #A855F7 0%, #6B21A8 100%)",
  },
  {
    // Indigo/Blue
    background: "linear-gradient(135deg, #3B82F6 0%, #1E40AF 100%)",
  },
  {
    // Green
    background: "linear-gradient(135deg, #22C55E 0%, #14B8A6 100%)",
  },
  {
    // Orange/Red
    background: "linear-gradient(135deg, #F97316 0%, #DC2626 100%)",
  },
];

export default function ChatBot({
  modal = false,
  isOpen: propIsOpen = false,
  onClose,
  iframeMode = false,
  botId: propBotId,
  customization: propCustomization = {},
  suggestionMode = "default",
  welcomeMessageIndex = null,
}) {
  const navigate = useNavigate();
  const [askedQuestions, setAskedQuestions] = useState([]);

  const urlParams = new URLSearchParams(window.location.search);
  const botId = propBotId || urlParams.get("botId") || "kimavi-101";
  const [sessionId] = useState(uuidv4());

  // ---------------------------------------------------------------------------
  // Default colors and styles (fallbacks)
  // ---------------------------------------------------------------------------
  const defaultHeaderBackgroundColor = "#1e40af";
  const defaultHeaderFontColor = "#ffffff";
  const defaultUserIconColor = "#2DD4BF";
  const defaultIconBackgroundColor = "#000000";
  const defaultIconFrontColor = "#ffffff";
  const defaultUserBackgroundColor = "#000000";
  const defaultUserFontColor = "#ffffff";
  const defaultBackgroundColor = "#ffffff";
  const defaultForegroundColor = "#000000";
  const defaultButtonBackgroundColor = "#000000";
  const defaultButtonFontColor = "#ffffff";
  const defaultBotMessageBackgroundColor = "#e2e8f0";
  const defaultBotMessageTextColor = "#2d3748";

  const [thinkingDots, setThinkingDots] = useState("");

  // Merged customization from props + query params
  const [customization, setCustomization] = useState({
    logo: urlParams.get("logo") || propCustomization.logo || defaultBotLogo,
    backgroundColor:
      urlParams.get("backgroundColor") ||
      propCustomization.backgroundColor ||
      defaultBackgroundColor,
    foregroundColor:
      urlParams.get("foregroundColor") ||
      propCustomization.foregroundColor ||
      defaultForegroundColor,
    fontFamily:
      urlParams.get("fontFamily") ||
      propCustomization.fontFamily ||
      "Arial, sans-serif",
    headerTitle:
      urlParams.get("headerTitle") ||
      propCustomization.headerTitle ||
      "Kimavi AI Agent - Built on ChatGPT",
    // Additional style overrides
    userMessageBackgroundColor: propCustomization.userMessageBackgroundColor,
    userMessageTextColor: propCustomization.userMessageTextColor,
    userIconColor: propCustomization.userIconColor,
    botMessageBackgroundColor: propCustomization.botMessageBackgroundColor,
    botMessageTextColor: propCustomization.botMessageTextColor,
  });

  const [isOpen, setIsOpen] = useState(iframeMode ? true : propIsOpen);
  const [messages, setMessages] = useState(() => {
    const messageIndex =
      welcomeMessageIndex !== null &&
      welcomeMessageIndex >= 0 &&
      welcomeMessageIndex < welcomeMessages.length
        ? welcomeMessageIndex
        : Math.floor(Math.random() * welcomeMessages.length);

    const selectedMessage = welcomeMessages[messageIndex];
    return [
      {
        sender: "bot",
        text: `
## ${selectedMessage.greeting}  

---

I can do following:

${selectedMessage.capabilities.map((cap) => `* ${cap}`).join("\n")}

---

${selectedMessage.closing}
`,
        isInitial: true,
        animate: true,
      },
    ];
  });

  // ---------------------------------------------------------------------------
  // We start with 2 default questions
  // Suggestions must never exceed 2
  // ---------------------------------------------------------------------------
  const [suggestions, setSuggestions] = useState(() => {
    switch (suggestionMode) {
      case "demos":
        return [demoGuides, demoVideo];
      case "random":
        const availableQuestions = knownQA
          .map((qa) => qa.question)
          .filter((q) => ![demoGuides, demoVideo].includes(q));
        return shuffleArray(availableQuestions).slice(0, 2);
      default:
        return [demoGuides, demoVideo];
    }
  });

  // Whenever user sends a message, we can add more knownQA if suggestions < 2
  useEffect(() => {
    const userMessagesCount = messages.filter(
      (msg) => msg.sender === "user"
    ).length;

    if (userMessagesCount >= 0 && suggestions.length < 2) {
      const unAdded = knownQA
        .map((qa) => qa.question)
        .filter(
          (q) =>
            ![demoGuides, demoVideo].includes(q) &&
            !suggestions.includes(q) &&
            !askedQuestions.includes(q)
        );

      if (unAdded.length > 0) {
        const randomIndex = Math.floor(Math.random() * unAdded.length);
        const nextQ = unAdded[randomIndex];
        setSuggestions((prev) => {
          const updated = [...prev, nextQ];
          if (updated.length > 2) return updated.slice(0, 2);
          return updated;
        });
      }
    }
  }, [messages, suggestions, askedQuestions]);

  // Basic states
  const [input, setInput] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [botResponse, setBotResponse] = useState("");
  const [typingIntervalId, setTypingIntervalId] = useState(null);
  const [showPopup, setShowPopup] = useState(true);
  const CHAR_LIMIT = 120;

  const [expectingEmail, setExpectingEmail] = useState(false);
  const [emailCollected, setEmailCollected] = useState(false);

  const userMessagesCount = messages.filter(
    (msg) => msg.sender === "user"
  ).length;

  // Animate "Thinking..." dots
  useEffect(() => {
    let dotsInterval;
    if (isTyping && !botResponse) {
      dotsInterval = setInterval(() => {
        setThinkingDots((prev) => (prev.length >= 5 ? "" : prev + "."));
      }, 500);
    } else {
      setThinkingDots("");
    }
    return () => {
      if (dotsInterval) clearInterval(dotsInterval);
    };
  }, [isTyping, botResponse]);

  // Auto-scroll references
  const messagesEndRef = useRef(null);
  const lastUserMessageRef = useRef(null);
  const lastBotMessageRef = useRef(null);

  // Production or dev?
  const apiBaseUrl =
    NODE_ENV === "development"
      ? "http://localhost:5001/json-storage-bed47/us-central1/api"
      : "https://us-central1-json-storage-bed47.cloudfunctions.net/api";

  // Optional: fetch initial config from server
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/chatbot-config`, {
          params: { botId },
        });
        const config = response.data;
        if (config.initialMessages) {
          setMessages(config.initialMessages);
        }
        if (config.suggestions) {
          // keep max 2
          const combined = [
            ...suggestions,
            ...config.suggestions.filter((s) => !suggestions.includes(s)),
          ].slice(0, 2);
          setSuggestions(combined);
        }
        setCustomization((prev) => ({
          ...prev,
          ...config.customization,
        }));
      } catch (error) {
        console.error("Error fetching chatbot configuration:", error);
      }
    };
    // fetchInitialData(); // Uncomment if needed
  }, [botId, apiBaseUrl]);

  // Auto-scroll logic
  const previousMessageCount = useRef(messages.length);

  useEffect(() => {
    if (messages.length > previousMessageCount.current) {
      const lastMessage = messages[messages.length - 1];

      // Function to scroll to bottom of container
      const scrollToBottom = (container) => {
        if (container) {
          setTimeout(() => {
            container.scrollTop = container.scrollHeight;
          }, 100);
        }
      };

      // Find the closest parent modal or messages container
      const container = modal
        ? messagesEndRef.current?.closest(".modal-messages-container")
        : messagesEndRef.current?.closest(".flex-1.overflow-y-auto");

      scrollToBottom(container);
      previousMessageCount.current = messages.length;
    }
  }, [messages, modal]);

  // Update the useEffect for typing indicator scroll
  useEffect(() => {
    const scrollToLastUserMessage = () => {
      // Find the closest parent modal or messages container
      const container = iframeMode
        ? messagesEndRef.current?.closest(".absolute.inset-0.overflow-y-auto")
        : messagesEndRef.current?.closest(".flex-1.overflow-y-auto");

      if (container) {
        // Find the last user message within this specific container
        const userMessages = container.querySelectorAll(
          '[class*="justify-end"]'
        );
        const lastUserMessage = userMessages[userMessages.length - 1];

        if (lastUserMessage) {
          setTimeout(() => {
            // Calculate scroll position to show both question and answer
            const containerHeight = container.clientHeight;
            const userMessageTop = lastUserMessage.offsetTop;

            // Scroll to position that shows the user message at 1/3 from the top
            const scrollPosition = Math.max(
              0,
              userMessageTop - containerHeight / 3
            );

            container.scrollTo({
              top: scrollPosition,
              behavior: "smooth",
            });
          }, 100);
        }
      }
    };

    if (isTyping) {
      scrollToLastUserMessage();
    }
  }, [isTyping, iframeMode]);

  // Update the message scroll effect
  useEffect(() => {
    if (messages.length > previousMessageCount.current && !isTyping) {
      const lastMessage = messages[messages.length - 1];

      // Only auto-scroll to bottom for user messages
      if (lastMessage.sender === "user") {
        const container = iframeMode
          ? document.querySelector(".absolute.inset-0.overflow-y-auto")
          : document.querySelector(".flex-1.overflow-y-auto");

        if (container) {
          setTimeout(() => {
            container.scrollTop = container.scrollHeight;
          }, 100);
        }
      }

      previousMessageCount.current = messages.length;
    }
  }, [messages, iframeMode, isTyping]);

  // Cleanup intervals
  useEffect(() => {
    return () => {
      if (typingIntervalId) clearInterval(typingIntervalId);
    };
  }, [typingIntervalId]);

  // Example: show popup for 10s
  useEffect(() => {
    let popupTimer;
    if (showPopup) {
      popupTimer = setTimeout(() => {
        setShowPopup(false);
      }, 10000);
    }
    return () => {
      if (popupTimer) clearTimeout(popupTimer);
    };
  }, [showPopup]);

  // ---------------------------------------------------------------------
  // Handling user input
  // ---------------------------------------------------------------------
  const handleInputChange = (e) => {
    if (e.target.value.length <= CHAR_LIMIT) {
      setInput(e.target.value);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      sendMessage();
    }
  };

  // Send user message
  const sendMessage = async (messageText = input) => {
    if (messageText.trim() === "") return;

    const customerId = "101";

    // If we are expecting an email:
    const validateEmail = (email) => {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    };
    if (expectingEmail) {
      if (validateEmail(messageText)) {
        setMessages((prev) => [...prev, { sender: "user", text: messageText }]);
        setEmailCollected(true);

        await getBotResponse(messageText);

        setMessages((prev) => [
          ...prev,
          {
            sender: "bot",
            text: "Thank you for providing your email! A human will be in touch via email. For further help you can also email us at admin@kimavi.com",
          },
        ]);
        setExpectingEmail(false);
        setInput("");
        return;
      } else {
        setMessages((prev) => [
          ...prev,
          { sender: "user", text: messageText },
          {
            sender: "bot",
            text: "Oops! That doesn't look like a valid email address. Could you please double-check and provide a valid email?",
          },
        ]);
        setInput("");
        return;
      }
    }

    // If we've reached 10 user messages but no email => end
    if (userMessagesCount >= 10 && !emailCollected) {
      setMessages((prev) => [
        ...prev,
        { sender: "user", text: messageText },
        {
          sender: "bot",
          text: "For further assistance, please contact us at admin@kimavi.com. Our team will reach out to help you further once we have your email address.",
        },
      ]);
      setInput("");
      return;
    }

    // Normal message flow
    const userMessageObj = { sender: "user", text: messageText };
    setMessages((prev) => [...prev, userMessageObj]);
    setInput("");
    setIsTyping(true);

    try {
      // Get response from bot
      const { text: responseText, question: answeredQuestion } =
        await getBotResponse(messageText);

      if (answeredQuestion) {
        setAskedQuestions((prev) => [...prev, answeredQuestion]);
      }

      console.log("Bot response:", responseText);

      let { action, finalText } = parseServerMedia(responseText);
      console.log("Action:", action, "Final text:", finalText);

      const fallback = handleNavigateFallback(action, finalText);
      action = fallback.finalAction;
      finalText = fallback.finalText;

      switch (action) {
        case "guide":
          // Possibly highlight the iFrame or do special UI
          break;
        case "video":
          // Could do something special for video
          break;
        case "navigate":
          break;
        case "email":
          setExpectingEmail(true);
          break;
        default:
          // doNothing
          break;
      }

      setMessages((prev) => [...prev, { sender: "bot", text: finalText }]);
      setIsTyping(false);

      setTimeout(() => {
        const inputElement = document.querySelector('input[type="text"]');
        if (inputElement) {
          inputElement.focus();
        }
      }, 100);

      if (checkForEmailRequest(finalText)) {
        setExpectingEmail(true);
      }
      if (
        userMessagesCount >= 4 &&
        !emailCollected &&
        !expectingEmail &&
        !checkForEmailRequest(finalText)
      ) {
        setMessages((prev) => [
          ...prev,
          {
            sender: "bot",
            text: "To help us serve you better, please provide your email address. ",
          },
        ]);
        setExpectingEmail(true);
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setMessages((prev) => [
        ...prev,
        { sender: "bot", text: "Sorry, something went wrong." },
      ]);
      setIsTyping(false);
    }
  };

  const checkForEmailRequest = (botMessage) => {
    const emailRequestPhrases = [
      "could you please share your email address",
      "please provide your email address",
      "could you provide your email address",
      "can you share your email address",
      "may i have your email address",
      "what's your email address",
      "please provide your email",
      "to help us serve you better",
    ];
    const lowerCaseMessage = botMessage.toLowerCase();
    return emailRequestPhrases.some((phrase) =>
      lowerCaseMessage.includes(phrase)
    );
  };

  // ---------------------------------------------------------------------
  // Checking known QA or calling real API
  // ---------------------------------------------------------------------
  const getBotResponse = async (userInput) => {
    // 1) Check known QAs
    const matchingQA = knownQA.find(
      (qa) => qa.question.toLowerCase() === userInput.toLowerCase()
    );
    const customerId = "101";

    if (matchingQA) {
      setIsTyping(true);
      await new Promise((resolve) => setTimeout(resolve, 500));
      setIsTyping(false);
      return { text: matchingQA.answer, question: matchingQA.question };
    } else {
      // 2) Call server
      try {
        const payload = {
          botId,
          customerId,
          sessionId,
          message: userInput,
          messages,
        };
        const response = await axios.post(`${apiBaseUrl}/chat`, payload, {
          headers: { "Content-Type": "application/json" },
        });

        return { text: response.data.reply };
      } catch (error) {
        console.error("Error fetching bot response:", error);
        toast.error("Failed to get response from the chatbot.");
        return { text: "Sorry, I am having trouble responding right now." };
      }
    }
  };

  // ---------------------------------------------------------------------
  // Suggestion clicks
  // ---------------------------------------------------------------------
  const handleSuggestionClick = (suggestion) => {
    setSuggestions((prev) => prev.filter((s) => s !== suggestion));
    sendMessage(suggestion);
  };

  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Successfully copied.", { position: "bottom-center" });
      })
      .catch((err) => {
        console.error("Could not copy text:", err);
        toast.error("Failed to copy text.");
      });
  };

  // Refresh or reset
  const handleRefreshClick = () => {
    setAskedQuestions([]);
    setSuggestions([demoGuides, demoVideo]);
  };

  // ---------------------------------------------------------------------
  // Rendering
  // ---------------------------------------------------------------------
  const { logo, backgroundColor, foregroundColor, fontFamily, headerTitle } =
    customization;

  const renderMediaContent = (msg, idx) => {
    const text = msg.text.trim();
    const isYouTubeLink = /^https?:\/\/(www\.)?youtube\.com\//.test(text);
    const isIFrame = text.startsWith("<iframe");

    const shouldDisplaySuggestions =
      !emailCollected && !expectingEmail && !checkForEmailRequest(text);
    const displayedSuggestions = shouldDisplaySuggestions ? suggestions : [];

    if (isIFrame) {
      const responsiveIFrame = text
        .replace(/width="[^"]*"/, 'width="100%"')
        .replace(/height="[^"]*"/, 'height="600"');
      return (
        <>
          <div dangerouslySetInnerHTML={{ __html: responsiveIFrame }} />
          {displayedSuggestions.length > 0 && (
            <div className="mt-5 mb-2 flex flex-col gap-2">
              {displayedSuggestions.map((sug, i) => (
                <button
                  key={i}
                  className="w-full px-3 py-2 rounded-xl bg-gradient-to-r from-blue-500 to-purple-600 text-white text-xs hover:shadow-blue-500/25 transition-all duration-300"
                  onClick={() => handleSuggestionClick(sug)}
                >
                  {sug}
                </button>
              ))}
            </div>
          )}
        </>
      );
    }

    if (isYouTubeLink) {
      return (
        <>
          <p>Please watch this video!</p>
          <ReactPlayer url={text} controls width="100%" height="360px" />
          {displayedSuggestions.length > 0 && (
            <div className="mt-5 mb-2 flex flex-col gap-2">
              {displayedSuggestions.map((sug, i) => (
                <button
                  key={i}
                  className="w-full px-3 py-2 rounded-xl bg-gradient-to-r from-blue-500 to-purple-600 text-white text-xs hover:shadow-blue-500/25 transition-all duration-300"
                  onClick={() => handleSuggestionClick(sug)}
                >
                  {sug}
                </button>
              ))}
            </div>
          )}
        </>
      );
    }

    return (
      <>
        <ReactMarkdown
          components={{
            p: ({ node, children, ...props }) => (
              <p className="mb-2" {...props}>
                {children}
              </p>
            ),
            h1: ({ node, children, ...props }) => (
              <h1 className="text-2xl font-bold my-2" {...props}>
                {children}
              </h1>
            ),
            h2: ({ node, children, ...props }) => (
              <h2 className="text-xl font-semibold my-2" {...props}>
                {children}
              </h2>
            ),
            h3: ({ node, children, ...props }) => (
              <h3 className="text-lg font-semibold my-2" {...props}>
                {children}
              </h3>
            ),
            h4: ({ node, children, ...props }) => (
              <h4 className="text-base font-semibold my-2" {...props}>
                {children}
              </h4>
            ),
            ul: ({ node, children, depth, ...props }) => (
              <ul className="list-disc ml-5" {...props}>
                {children}
              </ul>
            ),
            ol: ({ node, children, depth, ...props }) => (
              <ol className="list-decimal ml-5" {...props}>
                {children}
              </ol>
            ),
            li: ({ node, children, ...props }) => (
              <li className="my-1" {...props}>
                {children}
              </li>
            ),
            code: ({ node, inline, className, children, ...props }) => {
              const match = /language-(\w+)/.exec(className || "");
              return !inline && match ? (
                <SyntaxHighlighter
                  style={materialDark}
                  language={match[1]}
                  PreTag="div"
                  {...props}
                >
                  {String(children).replace(/\n$/, "")}
                </SyntaxHighlighter>
              ) : (
                <code className="bg-gray-100 p-1 rounded" {...props}>
                  {children}
                </code>
              );
            },
            strong: ({ node, children, ...props }) => (
              <strong {...props}>{children}</strong>
            ),
            em: ({ node, children, ...props }) => (
              <em {...props}>{children}</em>
            ),
            blockquote: ({ node, children, ...props }) => (
              <blockquote
                className="border-l-4 border-gray-300 pl-4 italic my-2"
                {...props}
              >
                {children}
              </blockquote>
            ),
            hr: ({ node, ...props }) => <hr className="my-4" {...props} />,
            img: ({ node, ...props }) => (
              <img className="my-2 max-w-full" {...props} alt="" />
            ),
            a: ({ node, children, ...props }) => (
              <a
                className="text-blue-500 hover:underline"
                target="_blank"
                rel="noopener noreferrer"
                {...props}
              >
                {children}
              </a>
            ),
          }}
        >
          {text}
        </ReactMarkdown>
        {displayedSuggestions.length > 0 && (
          <div className="mt-5 mb-2 flex flex-col gap-2">
            {displayedSuggestions.map((sug, i) => (
              <button
                key={i}
                className="w-full px-3 py-2 rounded-xl bg-gradient-to-r from-blue-500 to-purple-600 text-white text-xs hover:shadow-blue-500/25 transition-all duration-300"
                onClick={() => handleSuggestionClick(sug)}
              >
                {sug}
              </button>
            ))}
          </div>
        )}
      </>
    );
  };

  // Add state for current gradient index
  const [currentGradientIndex, setCurrentGradientIndex] = useState(0);

  // Add effect for gradient rotation
  useEffect(() => {
    if (messages[0]?.isInitial) {
      const intervalId = setInterval(() => {
        setCurrentGradientIndex(
          (prev) => (prev + 1) % gradientCombinations.length
        );
      }, 3000);

      return () => clearInterval(intervalId);
    }
  }, [messages]);

  const renderMessageRow = (msg, idx) => {
    if (msg.sender === "bot") {
      const text = msg.text.trim();
      const isYouTubeLink = /^https?:\/\/(www\.)?youtube\.com\//.test(text);
      const isIFrame = text.startsWith("<iframe");
      const isMediaContent = isYouTubeLink || isIFrame;

      return (
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.3 }}
          key={idx}
          className="flex items-start space-x-2 sm:space-x-3 mb-4 sm:mb-6 w-full mt-3"
          ref={idx === messages.length - 1 ? lastBotMessageRef : null}
        >
          {!isMediaContent && (
            <div
              className={`flex-shrink-0 mt-2 z-10 relative w-8 ${
                !modal && !iframeMode ? "mx-3" : ""
              }`}
            >
              <BotLogo />
            </div>
          )}
          <motion.div
            initial={{ scale: 0.95 }}
            animate={{
              scale: 1,
              background: msg.isInitial
                ? gradientCombinations[currentGradientIndex].background
                : undefined,
            }}
            transition={{
              scale: { duration: 0.2 },
              background: { duration: 1.5, ease: "easeInOut" },
            }}
            className={`${
              isMediaContent
                ? "w-full"
                : msg.isInitial && !modal
                ? "max-w-[70%] sm:max-w-[80%]"
                : "max-w-[70%] sm:max-w-[80%]"
            } relative backdrop-blur-sm rounded-2xl ${
              !isMediaContent && "rounded-tl-sm"
            } z-[5]`}
            style={{
              backgroundColor: msg.isInitial
                ? "transparent"
                : "rgba(243, 244, 246, 0.8)",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.05)",
              border: msg.isInitial
                ? "none"
                : "1px solid rgba(229, 231, 235, 0.5)",
              width: "100%",
              display: "block",
            }}
          >
            <div
              className={`p-2 sm:p-4 font-sans text-xs ${
                msg.isInitial ? "text-white" : ""
              }`}
            >
              {renderMediaContent(msg, idx)}
            </div>
            {!isMediaContent && (
              <motion.div
                className="absolute -left-2 top-3 w-2 h-2 rotate-45"
                animate={{
                  background: msg.isInitial
                    ? gradientCombinations[currentGradientIndex].background
                    : undefined,
                }}
                transition={{ duration: 1.5, ease: "easeInOut" }}
                style={{
                  backgroundColor: msg.isInitial
                    ? "transparent"
                    : "rgba(243, 244, 246, 0.8)",
                  borderLeft: msg.isInitial
                    ? "none"
                    : "1px solid rgba(229, 231, 235, 0.5)",
                  borderBottom: msg.isInitial
                    ? "none"
                    : "1px solid rgba(229, 231, 235, 0.5)",
                }}
              />
            )}
          </motion.div>
        </motion.div>
      );
    } else {
      return (
        <motion.div
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.3 }}
          key={idx}
          className="flex justify-end mb-4 sm:mb-6 w-full"
          ref={idx === messages.length - 1 ? lastUserMessageRef : null}
        >
          <div className="flex items-end space-x-2 sm:space-x-3 max-w-[75%] sm:max-w-[60%]">
            <div className="relative w-full">
              <div
                className="p-2 sm:p-4 text-xs rounded-2xl rounded-tr-sm backdrop-blur-sm font-sans"
                style={{
                  background:
                    "linear-gradient(135deg, #0288D1 0%, #03A9F4 100%)",
                  color: "#FFFFFF",
                  boxShadow: "0 2px 8px rgba(3, 169, 244, 0.15)",
                  width: "100%",
                  display: "block",
                }}
              >
                {msg.text}
              </div>
              <div
                className="absolute -right-2 top-3 w-2 h-2 rotate-45"
                style={{
                  background:
                    "linear-gradient(135deg, #0288D1 0%, #03A9F4 100%)",
                }}
              />
            </div>
            <div className="flex-shrink-0 mb-1 p-1 rounded-full bg-gradient-to-br from-blue-500 to-blue-600">
              <FaUser className="text-base text-white" />
            </div>
          </div>
        </motion.div>
      );
    }
  };

  // ---------------------------------------------------------------------
  // Main chat content
  // ---------------------------------------------------------------------
  const chatContent = (
    <>
      {/* CHANGED: Removed 'flex-1' so we don't clash with parent's scroll area */}
      <div
        className="overflow-y-auto p-4"
        style={{ fontFamily, backgroundColor, color: foregroundColor }}
      >
        {messages.map((msg, idx) => renderMessageRow(msg, idx))}

        {isTyping && botResponse && (
          <div className="flex items-start space-x-2 my-2">
            <div
              className="p-2 rounded-lg"
              style={{
                width: "100%",
                backgroundColor:
                  customization.botMessageBackgroundColor ||
                  defaultBotMessageBackgroundColor,
                color:
                  customization.botMessageTextColor ||
                  defaultBotMessageTextColor,
              }}
            >
              <ReactMarkdown>{botResponse}</ReactMarkdown>
            </div>
          </div>
        )}

        {isTyping && !botResponse && (
          <div className="flex items-start space-x-2 sm:space-x-3 mb-4 sm:mb-6 w-full">
            <div className="flex-shrink-0 mt-2 z-10 relative w-8">
              <BotLogo />
            </div>
            <motion.div
              initial={{ scale: 0.95, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.3, ease: "easeOut" }}
              className="max-w-[80%] sm:max-w-[70%] relative backdrop-blur-sm rounded-2xl rounded-tl-sm z-[5]"
              style={{
                backgroundColor: "rgba(243, 244, 246, 0.8)",
                border: "1px solid rgba(229, 231, 235, 0.5)",
                color: "#374151",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.05)",
                width: "100%",
                display: "block",
              }}
            >
              <div className="p-3 sm:p-4 text-xs font-sans">
                <div className="flex items-center space-x-1">
                  <span className="font-medium text-gray-700">Thinking</span>
                  <motion.span
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: [0, 1, 1, 0],
                      transition: {
                        times: [0, 0.3, 0.7, 1],
                        duration: 1.5,
                        repeat: Infinity,
                      },
                    }}
                    className="text-blue-600 font-medium"
                  >
                    ...
                  </motion.span>
                </div>
              </div>
              <div
                className="absolute -left-2 top-3 w-2 h-2 rotate-45"
                style={{
                  backgroundColor: "rgba(243, 244, 246, 0.8)",
                  border: "1px solid rgba(229, 231, 235, 0.5)",
                  borderRight: "none",
                  borderTop: "none",
                }}
              />
            </motion.div>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
    </>
  );

  // ---------------------------------------------------------------------
  // Return: iFrame vs. modal vs. floating widget
  // ---------------------------------------------------------------------

  // Add iframe detection
  const isInIframe = window.self !== window.top;

  if (modal) {
    return (
      <div className="h-full flex flex-col bg-white overflow-hidden rounded-xl">
        {/* Header */}
        <div
          className="w-full flex items-center justify-between bg-white px-4 py-2"
          style={{
            backgroundColor: defaultHeaderBackgroundColor,
            color: defaultHeaderFontColor,
          }}
        >
          <div className="flex items-center space-x-2">
            <img src={logo} alt="Bot Logo" className="w-20 rounded-full" />
          </div>
          {/* Remove close button from modal view */}
        </div>

        {/* Chat content - Added modal-messages-container class */}
        <div className="flex-1 overflow-hidden">
          <div className="h-full overflow-y-auto modal-messages-container">
            {chatContent}
          </div>
        </div>

        {/* Input */}
        <div className="shrink-0 bg-white border-t border-gray-200">
          {!(emailCollected || userMessagesCount >= 10) && (
            <div className="w-full max-w-[calc(100%-1rem)] mx-auto">
              <ChatInput
                expectingEmail={expectingEmail}
                input={input}
                handleInputChange={handleInputChange}
                handleKeyPress={handleKeyPress}
                fontFamily={fontFamily}
                sendMessage={sendMessage}
                isTyping={isTyping}
                CHAR_LIMIT={CHAR_LIMIT}
              />
            </div>
          )}
          <PrivacyLine onClose={onClose} isOpen={isOpen} />
        </div>
      </div>
    );
  } else {
    return (
      <div className="relative z-50">
        {!isOpen && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="fixed bottom-4 right-4 z-100"
          >
            <div className="relative">
              {/* Glowing background effect */}
              <motion.div
                className="absolute inset-0 rounded-xl"
                animate={{
                  boxShadow: [
                    "0 0 20px rgba(59, 130, 246, 0.2)",
                    "0 0 40px rgba(168, 85, 247, 0.2)",
                    "0 0 20px rgba(59, 130, 246, 0.2)",
                  ],
                }}
                transition={{
                  duration: 4,
                  repeat: Infinity,
                  ease: "easeInOut",
                }}
              />

              {/* Main button */}
              <motion.button
                className="px-4 py-2 rounded-xl bg-gradient-to-r from-blue-500 to-purple-600 text-white font-bold hover:shadow-blue-500/25 transition-all duration-300 inline-flex items-center justify-center relative"
                onClick={() => {
                  setIsOpen(true);
                  setShowPopup(false);
                }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <motion.div
                  animate={{
                    y: [0, -3, 0],
                  }}
                  transition={{
                    duration: 1.5,
                    repeat: Infinity,
                    ease: "easeInOut",
                  }}
                  className="inline-flex items-center"
                >
                  <FaCommentDots className="w-4 h-4 mr-2" />
                  <span>Talk to AI Bot</span>
                </motion.div>
              </motion.button>

              {/* Notification dot with pulse */}
              <motion.div
                className="absolute -top-1 -right-1 w-3 h-3 bg-red-500 rounded-full"
                animate={{
                  scale: [1, 1.2, 1],
                  opacity: [0.7, 1, 0.7],
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut",
                }}
              />

              {/* "New!" label with fade in/out */}
              <motion.div
                className="absolute -top-2 -right-16 bg-blue-500 text-white text-xs px-2 py-1 rounded-full"
                animate={{
                  opacity: [0, 1, 0],
                }}
                transition={{
                  duration: 3,
                  repeat: Infinity,
                  ease: "easeInOut",
                }}
              >
                New!
              </motion.div>
            </div>
          </motion.div>
        )}

        {isOpen && (
          <div
            className="fixed bottom-4 right-4 w-full sm:w-full md:w-full max-w-md shadow-2xl rounded-lg border border-gray-200"
            style={{
              height: "680px",
              zIndex: 1000,
              backgroundColor,
              color: foregroundColor,
              fontFamily,
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            {/* Sticky Header */}
            <div
              className="sticky top-0 p-2 flex items-center justify-between z-20"
              style={{
                backgroundColor: defaultHeaderBackgroundColor,
                color: defaultHeaderFontColor,
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div className="flex items-center space-x-2">
                <img
                  src={logo}
                  alt="Bot Logo"
                  className="w-12 sm:w-20 rounded-full"
                />
              </div>
              {/* Update floating widget close button visibility */}
              {!isInIframe && (
                <button
                  onClick={() => setIsOpen(false)}
                  className="p-2 rounded-full hover:bg-black/10 transition-colors duration-200"
                >
                  <FaTimes className="text-lg sm:text-xl text-white" />
                </button>
              )}
            </div>

            {/* Messages */}
            <div className="flex-1 overflow-y-auto min-h-0 pt-2">
              {messages.map((message, index) => (
                <motion.div
                  key={index}
                  initial={message.animate ? { opacity: 0, y: 20 } : {}}
                  animate={message.animate ? { opacity: 1, y: 0 } : {}}
                  transition={{ duration: 0.5 }}
                >
                  {renderMessageRow(message, index)}
                </motion.div>
              ))}
              {isTyping && botResponse && (
                <div className="flex items-start space-x-2 my-2">
                  <div
                    className="p-2 rounded-lg"
                    style={{
                      width: "100%",
                      backgroundColor:
                        customization.botMessageBackgroundColor ||
                        defaultBotMessageBackgroundColor,
                      color:
                        customization.botMessageTextColor ||
                        defaultBotMessageTextColor,
                    }}
                  >
                    <ReactMarkdown>{botResponse}</ReactMarkdown>
                  </div>
                </div>
              )}
              {isTyping && !botResponse && (
                <div className="flex items-start space-x-2 sm:space-x-3 mb-4 sm:mb-6 w-full">
                  <div className="flex-shrink-0 mt-2 z-10 relative w-8">
                    <BotLogo />
                  </div>
                  <motion.div
                    initial={{ scale: 0.95, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ duration: 0.3, ease: "easeOut" }}
                    className="max-w-[80%] sm:max-w-[70%] relative backdrop-blur-sm rounded-2xl rounded-tl-sm z-[5]"
                    style={{
                      backgroundColor: "rgba(243, 244, 246, 0.8)",
                      border: "1px solid rgba(229, 231, 235, 0.5)",
                      color: "#374151",
                      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.05)",
                      width: "100%",
                      display: "block",
                    }}
                  >
                    <div className="p-3 sm:p-4 text-xs font-sans">
                      <div className="flex items-center space-x-1">
                        <span className="font-medium text-gray-700">
                          Thinking
                        </span>
                        <motion.span
                          initial={{ opacity: 0 }}
                          animate={{
                            opacity: [0, 1, 1, 0],
                            transition: {
                              times: [0, 0.3, 0.7, 1],
                              duration: 1.5,
                              repeat: Infinity,
                            },
                          }}
                          className="text-blue-600 font-medium"
                        >
                          ...
                        </motion.span>
                      </div>
                    </div>
                    <div
                      className="absolute -left-2 top-3 w-2 h-2 rotate-45"
                      style={{
                        backgroundColor: "rgba(243, 244, 246, 0.8)",
                        border: "1px solid rgba(229, 231, 235, 0.5)",
                        borderRight: "none",
                        borderTop: "none",
                      }}
                    />
                  </motion.div>
                </div>
              )}
              <div ref={messagesEndRef} />
            </div>

            {/* Input and Privacy */}
            <div className="shrink-0">
              {!(emailCollected || userMessagesCount >= 10) && (
                <div className="w-full max-w-[calc(100%-1rem)] mx-auto">
                  <ChatInput
                    expectingEmail={expectingEmail}
                    input={input}
                    handleInputChange={handleInputChange}
                    handleKeyPress={handleKeyPress}
                    fontFamily={fontFamily}
                    sendMessage={sendMessage}
                    isTyping={isTyping}
                    CHAR_LIMIT={CHAR_LIMIT}
                  />
                </div>
              )}
              <PrivacyLine onClose={() => setIsOpen(false)} isOpen={isOpen} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const ChatInput = ({
  expectingEmail,
  input,
  handleInputChange,
  handleKeyPress,
  fontFamily,
  sendMessage,
  isTyping,
  CHAR_LIMIT,
}) => (
  <div className="bg-white w-full relative z-10">
    <div className="p-2 sm:p-3 space-y-2 m-2 rounded-lg bg-blue-800 relative">
      {/* Character count badge */}
      {input && input.length > 0 && (
        <div
          className="absolute -top-8 right-2 px-2 py-0.5 bg-gray-700 text-white text-xs rounded-full transform transition-opacity duration-200 shadow-md"
          style={{
            opacity: input.length > CHAR_LIMIT * 0.8 ? "1" : "0.7",
            color: input.length > CHAR_LIMIT * 0.9 ? "#FFA07A" : "white",
          }}
        >
          {input.length}/{CHAR_LIMIT}
        </div>
      )}

      {expectingEmail && (
        <p className="text-white text-xs">
          We'd love to get in touch! Please provide your email:
        </p>
      )}
      <div className="flex items-center space-x-2">
        <input
          className={`flex-1 p-1 sm:p-2 text-xs rounded-md focus:outline-none text-gray-900 transition-opacity duration-200
            ${isTyping ? "opacity-50 cursor-not-allowed" : "opacity-100"}`}
          style={{
            fontFamily,
            border: "1px solid #e2e8f0",
          }}
          type="text"
          placeholder={
            expectingEmail ? "Enter your email address..." : "Type here..."
          }
          value={input}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          disabled={isTyping}
        />
        <button
          className={`px-2 py-1 text-xs rounded-md text-white whitespace-nowrap transition-all duration-200 
            ${isTyping ? "opacity-50 cursor-not-allowed" : "hover:opacity-90"}`}
          style={{
            background: "linear-gradient(135deg, #0288D1 0%, #03A9F4 100%)",
            fontFamily,
            boxShadow: "0 2px 4px rgba(3, 169, 244, 0.2)",
            minWidth: "40px",
          }}
          onClick={() => sendMessage()}
          disabled={input.length === 0 || isTyping}
        >
          Send
        </button>
      </div>
    </div>
  </div>
);

const PrivacyLine = ({ onClose, isOpen }) => {
  // Add isInIframe check inside the component
  const isInIframe = window.self !== window.top;

  return (
    <div className="bg-white text-[10px] sm:text-xs text-center p-1 sm:p-2 flex items-center justify-between border-t border-gray-200">
      <span className="flex-1 text-center text-gray-600">
        By chatting, you agree to our{" "}
        <a
          href="https://www.kimavi.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 hover:underline"
        >
          privacy policy
        </a>
      </span>
      {onClose && isOpen && !isInIframe && (
        <button
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700 transition-colors duration-200 ml-2 bg-gray-100 p-1.5 rounded-full hover:bg-gray-200"
          aria-label="Close chat"
        >
          <FaArrowDown className="text-sm text-blue-500" />
        </button>
      )}
    </div>
  );
};
