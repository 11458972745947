import dedent from "dedent";

const knownQA = [
  {
    question: "What is Kimavi?",
    answer: dedent`
      Kimavi is a platform focused on driving Sales and Customer Success through AI-enabled chat, video, and guided automation. It offers features such as:
      
      1. **Video Tutorials**: Easily create step-by-step video demos with our AI Script Generator.
      2. **How-To Guides**: Produce quick and effective how-to guides using our AI-powered browser extension.
      3. **AI Agent**: Train a ChatGPT-based Agent on your product, enabling it to engage prospects, capture leads, and provide tailored customer support.
      
      Together, these tools help businesses engage visitors, assist customers, and ultimately drive both sales growth and customer success.
      `,
  },
  {
    question: "What is Kimavi's vision?",
    answer: dedent`
      Our vision is to empower businesses worldwide to achieve exceptional Sales and Customer Success by leveraging AI-powered Agents enriched with interactive video demos, guided walkthroughs, and on-demand content. We aim to transform every customer interaction—whether pre-sales or post-sales—into a personalized, engaging, and impactful experience.
      `,
  },
  {
    question: "How can Kimavi help with both Sales and Customer Success?",
    answer: dedent`
      Kimavi empowers both Sales and Customer Success teams to:
      
      - **Engage Prospects in Real-Time**: Convert more visitors into leads by using an AI Agent trained on your product.
      - **Accelerate Sales Cycles**: Offer on-demand product demos, address objections instantly, and guide prospects through complex features, improving conversion rates.
      - **Enhance Customer Onboarding and Retention**: Provide step-by-step guided or automated walkthroughs to help customers understand and use your product effectively, reducing churn.
      - **Deliver Self-Service Support**: On-demand videos and guides ensure customers can easily find answers, enhancing user satisfaction and loyalty.
      `,
  },
  {
    question:
      "What are Guided Walkthroughs and how do they benefit Sales and Customer Success strategies?",
    answer: dedent`
      **Guided Walkthroughs** are interactive, step-by-step instructions that lead users through specific product features or workflows. They benefit both Sales and Customer Success by:
      
      - **For Sales**: Instantly show prospects how to complete key tasks, removing friction from the buying process and shortening sales cycles.
      - **For Customer Success**: Help new users easily adopt your product, improving onboarding efficiency, user confidence, and long-term satisfaction.
      `,
  },
  {
    question:
      "What are Automated Walkthroughs and how do they save time for Sales and Customer Success?",
    answer: dedent`
      **Automated Walkthroughs** are pre-recorded, step-by-step guides that play out automatically—users can sit back, watch, and learn.
      
      - **For Sales**: Scale your product demos without needing a live rep for every session, ensuring consistency in messaging and a higher volume of qualified leads.
      - **For Customer Success**: Offer 24/7 self-service training content, reducing repetitive support queries and freeing up your team to handle more complex customer issues.
      `,
  },
  {
    question:
      "What are On-Demand Videos and how do they boost both Sales and Customer Success?",
    answer: dedent`
      **On-Demand Videos** are dynamically generated video resources that answer customer questions whenever they need them.
      
      - **For Sales**: Instantly generate product walkthroughs and feature highlights for prospects, improving engagement and conversion rates.
      - **For Customer Success**: Provide customers with accessible, easy-to-follow video explanations at their convenience, improving retention, satisfaction, and overall product adoption.
      `,
  },
  {
    question:
      "How can Kimavi’s AI Agent enhance both Sales and Customer Success?",
    answer: dedent`
      The AI Agent, trained on your unique content, can:
      
      - **For Sales**: Engage visitors with real-time chat, recommend relevant demos, and capture lead information automatically.
      - **For Customer Success**: Offer immediate guidance, troubleshoot common issues with step-by-step instructions, and deliver on-demand videos that address specific customer queries, improving user satisfaction and retention.
      `,
  },
  {
    question:
      "How do Kimavi’s features help differentiate my business from competitors?",
    answer: dedent`
      Kimavi’s core differentiators include:
      
      1. **Guided Walkthroughs**: Interactive, step-by-step instructions that engage prospects and guide customers efficiently.
      2. **Automated Walkthroughs**: Hands-free demos and training sessions that save time for both Sales and Customer Success teams.
      3. **On-Demand Videos**: AI-generated video content that answers prospect and customer questions instantly.
      
      By combining these elements, Kimavi creates a seamless journey from prospect engagement to long-term customer success, setting you apart in the market.
      `,
  },
  // Existing Q&A entries remain unchanged below
  {
    question: "How can Kimavi help with sales success?",
    answer: dedent`
      Kimavi enhances sales success by providing tools that improve customer engagement across pre-sales, onboarding, and support. With features like a custom AI-powered Agent, in-app guides, and video tutorials, businesses can:
      
      - **Engage Prospects**: Interact with visitors in real-time using the Agent trained on your product.
      - **Assist Customers**: Provide step-by-step guides and videos directly through the Agent or in-app, enhancing customer onboarding and support.
      - **Increase Conversion Rates**: By offering personalized and immediate assistance, you can improve lead generation and customer satisfaction, leading to higher sales success.
      `,
  },
  {
    question: "What are the key features of Kimavi's Agent?",
    answer: dedent`
      Kimavi's Agent offers several key features:
      
      1. **Customizable Agent**: Tailor ChatGPT to align perfectly with your product and brand.
      2. **Seamless Integration**: Easily integrate into your website, mobile app, WhatsApp, Slack, or Zapier with minimal effort.
      3. **Advanced Analytics**: Gain insights into user interactions and improve engagement.
      4. **Multi-Platform Integration**: Connect with multiple platforms to reach prospects wherever they are.
      5. **Personalized Responses**: Train the Agent using your own content, including videos and how-to guides, to provide accurate and helpful answers.
      `,
  },
  {
    question: "How do I create an AI Agent with Kimavi?",
    answer: dedent`
      To create an AI Agent with Kimavi:
      
      1. **Sign Up**: Register for an account on Kimavi and choose the Advanced AI Creator subscription plan.
      2. **Create Content**: Use Kimavi's tools to create high-quality, rich media content such as videos, how-to guides, and documents.
      3. **Train the Agent**: Input your product information, FAQs, and the content you created to train the Agent.
      4. **Customize Appearance**: Personalize the Agent's look and feel to match your brand identity.
      5. **Integrate**: Deploy the Agent on your website, mobile app, or integrate with platforms like WhatsApp and Slack.
      6. **Monitor and Improve**: Use the advanced analytics to track interactions and continuously improve the Agent's performance.
      `,
  },
  {
    question: "What are the benefits of using Kimavi's How-To Guide Creator?",
    answer: dedent`
      Kimavi's How-To Guide Creator offers several benefits:
      
      - **AI-Powered Extension**: Quickly create guides while browsing using the AI-powered browser extension.
      - **Visual Editing**: Upload and edit images, annotate, and customize your content with ease.
      - **Easy Publishing**: Share guides in multiple formats with just a few clicks.
      - **Train Your Agent**: Use the high-quality guides you create to train your Agent, enhancing its effectiveness.
      - **Improve Onboarding**: Provide step-by-step instructions to assist customers and prospects, improving their experience with your product.
      `,
  },
  {
    question: "How can I create compelling product demo videos with Kimavi?",
    answer: dedent`
      Creating compelling product demo videos with Kimavi is simple:
      
      1. **Use the AI Script Generator**: Quickly generate video scripts using Kimavi's AI-powered tool.
      2. **Customize Your Video**: Personalize backgrounds, fonts, and themes to match your brand.
      3. **Add Voiceover**: Use AI-generated voices or record your own voice for narration.
      4. **Publish in Multiple Formats**: Create videos in landscape, portrait, or shorts formats suitable for various platforms.
      5. **Share and Train**: Use your videos to engage prospects, share on social media, or train your Agent for better customer interactions.
      `,
  },
  {
    question: "What are Kimavi's beliefs and commitments?",
    answer: dedent`
      Kimavi is built on four core beliefs and commitments:
      
      1. **Sales Success is Our Priority**: We believe businesses thrive when sales succeed. Our tools help you engage prospects, assist customers, and drive sales success.
      2. **AI is Transforming Sales Success**: We believe artificial intelligence is revolutionizing how businesses interact with prospects and customers.
      3. **Human + AI Equals Unmatched Sales & Success**: While AI enhances efficiency, the human touch remains invaluable. We ensure a harmonious blend of both.
      4. **Empowering Businesses to Lead the Change**: We equip you with tools to innovate prospect and customer interactions, enhance satisfaction, and drive growth.
      `,
  },
  {
    question: "How can I get started with Kimavi?",
    answer: dedent`
      To get started with Kimavi:
      
      1. **Sign Up**: Register for a free account to explore the platform.
      2. **Choose a Subscription Plan**: Select a plan that suits your needs, such as the Basic AI Creator or Advanced AI Creator for full features.
      3. **Create Content**: Start creating how-to guides, video tutorials, or train your Agent using our tools.
      4. **Integrate and Share**: Deploy your content on your website, mobile app, or share on social media platforms.
      5. **Contact Us**: For personalized demos or assistance, feel free to contact us at admin@kimavi.com.
      `,
  },
  {
    question:
      "Does Kimavi integrate with other platforms like WhatsApp and Slack?",
    answer: dedent`
      Yes, Kimavi's Agent can integrate with various platforms including WhatsApp, Slack, and more. This allows you to reach prospects and customers where they are. To integrate:
      
      1. **Access Integration Settings**: In the Agent section, navigate to Integrations.
      2. **Connect to Platforms**: Follow the prompts to link your accounts, such as WhatsApp Business or Slack workspace.
      3. **Customize and Test**: Configure messaging settings, personalize interactions, and test the Agent to ensure functionality.
      `,
  },
  {
    question: "How can Kimavi help in customer onboarding and support?",
    answer: dedent`
      Kimavi assists in customer onboarding and support by providing:
      
      - **Agent**: Offer instant, personalized assistance to customers through an AI Agent trained on your product.
      - **How-To Guides**: Create step-by-step guides to help customers navigate your product features.
      - **Video Tutorials**: Develop engaging videos to demonstrate product usage and best practices.
      - **In-App Integration**: Embed guides and videos directly into your application for seamless customer experiences.
      `,
  },
  {
    question: "What makes Kimavi different from its competitors?",
    answer: dedent`
      Kimavi stands out from its competitors by offering a comprehensive suite of tools that combine AI-powered Agents with rich media content creation. Our platform allows you to:
      
      - **Train ChatGPT on Your Own Content**: Enhance your Agent's effectiveness by training it with your own videos, guides, and documents.
      - **Create High-Quality Content Easily**: Use our AI-powered tools to create engaging how-to guides and video tutorials in minutes.
      - **Seamless Integration**: Integrate the Agent and content into your website, mobile app, and other platforms effortlessly.
      - **Advanced Analytics**: Gain deep insights into customer and prospect interactions to continuously improve your sales and support strategies.
      `,
  },
  {
    question:
      "Can you tell me more about Kimavi's experience and customer base?",
    answer: dedent`
      Kimavi has extensive experience in deploying in-app and on-premises how-to guide software for organizations including UBS, AbbVie, Westpac Bank, and the World Bank. Our team has deployed extensions across major financial institutions, passing all security and privacy checks. Additionally:
      
      - **23,000+ Subscribers**: We have a growing community of loyal subscribers.
      - **10 Million+ Views**: Our videos and guides have garnered over 10 million views across platforms.
      - **Enterprise-Level Expertise**: We bring enterprise-grade know-how to help businesses of all sizes achieve sales and customer success.
      `,
  },
  {
    question: "Ready to create your Agent?",
    answer: dedent`
      Absolutely! Sign up and choose the Advanced AI Creator plan, which includes the Agent feature. Create, train, personalize, and deploy your Agent in minutes to engage prospects, capture leads, and enhance both sales and customer success.
      `,
  },
  {
    question: "How do I use the AI Script Generator?",
    answer: dedent`
      To use the AI Script Generator in Kimavi:
      
      1. **Access the AI Script Generator**: Navigate to the Video Tutorials section and select the AI Script Generator.
      2. **Input Your Topic**: Enter the topic or key points you want to cover in your video.
      3. **Generate Script**: Let the AI generate a video script for you in seconds.
      4. **Customize the Script**: Review and edit the script as needed.
      5. **Proceed to Video Creation**: Use the script to create your video tutorial, adding visuals, voiceovers, and other customizations.
      `,
  },
  {
    question: "How can I install the Kimavi browser extension?",
    answer: dedent`
      To install the Kimavi browser extension:
      
      1. **Visit the Chrome Web Store**: Go to [Chrome Web Store](https://chromewebstore.google.com/detail/kimavi-create-how-to-vide/kcleenakjlelbmchbkfpgoldgglhdpne).
      2. **Click 'Add to Chrome'**: Install the extension in your browser.
      3. **Create How-To Guides**: Start creating guides directly from your browser with the AI-powered extension.
      4. **Login to Kimavi**: Ensure you're logged in to your Kimavi account to save and manage your guides.
      `,
  },
  {
    question: "How many subscribers and views does Kimavi have?",
    answer: dedent`
      Kimavi has over 23,000 subscribers and more than 10 million views on our videos and guides across platforms like YouTube, Facebook, Instagram, and Twitter. Our content empowers users worldwide to learn about various products and technologies.
      `,
  },
  {
    question: "How does Kimavi ensure data security and privacy?",
    answer: dedent`
      Kimavi is committed to ensuring data security and privacy. We have deployed solutions at major financial institutions, passing all security and privacy checks. We adhere to strict data protection policies and comply with relevant regulations to safeguard your information.
      `,
  },
  {
    question: "How to create Video Tutorials?",
    answer: "https://www.youtube.com/watch?v=2GIumvSUO_Q",
  },
  {
    question: "How to create How-To Tutorials?",
    answer: "https://www.youtube.com/watch?v=HJ27CY8T_nY",
  },
  {
    question: "Can you give me an example of a video that a user has created?",
    answer: "https://www.youtube.com/watch?v=024EELV89SA",
  },
  {
    question: "What is blog to video? Can you give me an example?",
    answer: "https://www.youtube.com/watch?v=sjFTTq3g5vU",
  },
  {
    question: "Can you create podcasts? Give me an example?",
    answer: "https://www.youtube.com/watch?v=3iptXOvnN4g",
  },
  {
    question: "Give me an example of a How-To video created by the extension?",
    answer: "https://www.youtube.com/shorts/7ijhV7yfkJQ",
  },
  {
    question: "Who are your competitors?",
    answer:
      "Try us today and experience the difference! Of course, we have fantastic competitors, and we continuously learn from each other. But jokes aside, give us a try and see what sets us apart!",
  },
  {
    question: "How do I create an AI Agent for my products?",
    answer: dedent`
      To create an AI Agent for your product using Kimavi:
      
      1. **Sign Up**: Choose a plan that includes the Agent feature (Advanced AI Creator).
      2. **Train Your Agent**: Add product details, FAQs, and content to guide the Agent’s knowledge.
      3. **Integrate**: Embed the Agent on your website or connect it to WhatsApp and Slack.
      4. **Customize Appearance**: Reflect your brand in the Agent’s look and feel.
      5. **Test and Refine**: Interact with the Agent, gather feedback, and improve responses over time.
      
      With these steps, your Agent can enhance both sales and customer success activities.
      `,
  },
  {
    question: "What subscription plans does Kimavi offer?",
    answer: dedent`
      Kimavi offers three subscription tiers:
      
      1. **Free Subscription**: Basic preview of features, limited guides and video tutorials.
      2. **Basic AI Creator ($10/month)**: Unlock AI-assisted guides and videos, basic analytics, and email support.
      3. **Advanced AI Creator ($20/month)**: Access premium AI features, create an Agent, advanced analytics, customization, SSO integration, and priority support.
      
      Each tier supports your journey towards enhanced sales and customer success.
      `,
  },
  {
    question: "How do I use the Image Editor in Kimavi?",
    answer: dedent`
      To use the Image Editor:
      
      1. **Upload or Select an Image**: Within the video or guide creation tool.
      2. **Edit the Image**: Draw, blur, crop, and position images as needed.
      3. **Save and Incorporate**: Insert the edited image into your guide or tutorial to enhance clarity.
      
      Visual customization helps both prospects and customers understand your product better.
      `,
  },
  {
    question: "How can I publish videos in multiple formats?",
    answer: dedent`
      Publishing in multiple formats (Landscape, Portrait, Shorts) ensures broader reach:
      
      1. **Create Your Video**: Generate content using the AI Script Generator.
      2. **Select Formats**: Choose desired video layouts.
      3. **Publish**: Kimavi automatically produces multiple formats for diverse platforms, aiding both sales outreach and customer education.
      `,
  },
  {
    question: "How do I customize the appearance of my video tutorials?",
    answer: dedent`
      To customize appearance:
      
      1. **Access Settings**: In the Video Script Creator, choose backgrounds, fonts, and themes.
      2. **Add Branding**: Include logos, colors, and footers to match your brand.
      3. **Preview and Adjust**: Review changes before publishing.
      
      A polished look enhances professionalism and trust, positively impacting both sales and customer retention.
      `,
  },
  {
    question: "How do I connect my Agent to WhatsApp and Slack?",
    answer: dedent`
      Connecting your Agent:
      
      1. **Go to Integrations**: In your Agent settings, select WhatsApp or Slack.
      2. **Authorize Kimavi**: Follow on-screen instructions to link accounts.
      3. **Test and Monitor**: Interact with the Agent to ensure flawless operation.
      
      Reaching customers on their preferred platforms boosts engagement and support quality.
      `,
  },
  {
    question: "Can I use my own voice in the video tutorials?",
    answer: dedent`
      Yes, you can:
      
      1. **Record Your Voice**: Directly within the Video Script Creator.
      2. **Upload Audio**: Use pre-recorded files if you prefer.
      3. **Edit and Sync**: Ensure your narration matches the visuals.
      
      A personal touch in narration builds rapport with prospects and customers.
      `,
  },
  {
    question: "What advanced features does Kimavi offer?",
    answer: dedent`
      Advanced features include:
      
      - **LaTeX Formulas**: For technical or educational content.
      - **Emojis and Icons**: Enhance engagement and clarity.
      - **Code Snippets**: Ideal for developer-centric demos.
      - **Multi-Language Support**: Reach global audiences.
      - **Interactive Elements**: Include quizzes or Q&A steps.
      
      These features enrich both sales presentations and customer training materials.
      `,
  },
  {
    question: "If Kimavi were a superhero, what powers would it have?",
    answer: dedent`
      Kimavi would have:
      
      - **Content Creation Beam**: Instantly generate videos, guides, and demos.
      - **AI Sidekick**: A tireless assistant delivering answers in real-time.
      - **Analytics X-Ray Vision**: Understand customer needs at a glance.
      - **Customization Shield**: Tailor experiences for maximum impact.
      
      These “powers” help conquer sales targets and ensure customer happiness.
      `,
  },
  {
    question: "What’s Kimavi’s favorite type of coffee?",
    answer:
      "A latte with a shot of AI! It keeps our content creation energized, fueling both sales momentum and customer success ☕🤖",
  },
];

export default knownQA;
