// Authprovier.js

import React, { useState, useEffect, createContext } from "react";
import { auth, db } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";
import { doc, onSnapshot, collection } from "firebase/firestore";

export const AuthContext = createContext({
  user: null,
  isPaid: false,
  idToken: null, // Add this line
  treeMembership: [], // Variable to store the names of trees the user belongs to
  treeAdminStatus: [], // Variable to store the trees where the user is an admin
});
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isPaid, setIsPaid] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isAuthChecking, setIsAuthChecking] = useState(true);
  const [userEmail, setUserEmail] = useState(null);
  const [planId, setPlanId] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [isNewlyLoggedIn, setIsNewlyLoggedIn] = useState(false);
  const [treeMembership, setTreeMembership] = useState([]);
  const [treeAdminStatus, setTreeAdminStatus] = useState([]);

  useEffect(() => {
    // console.log("AuthProvider useEffect running");
    // console.log("user", user);
    const unsubscribeAuth = onAuthStateChanged(
      auth,
      (currentUser) => {
        setUser(currentUser);

        if (currentUser) {
          setUserEmail(currentUser.email);
          setIsNewlyLoggedIn(true);
          currentUser.getIdToken(true).then((token) => {
            setIdToken(token);
          });
          const userDocRef = doc(collection(db, "users"), currentUser.email);
          const unsubscribeFirestore = onSnapshot(
            userDocRef,
            (docSnapshot) => {
              const data = docSnapshot.data();
              if (data) {
                setIsPaid(data.paid || false);
                setUserData(data);
                setPlanId(data.plan_id);

                // Destructure and rename the familyTree properties
                if (data.familyTree) {
                  const { treeName: membership, isTreeAdmin: adminStatus } =
                    data.familyTree;
                  setTreeMembership(membership);
                  setTreeAdminStatus(adminStatus);
                }
                setIsAuthChecking(false);
                // console.log("userData1", data);
              }
            },
            (error) => {
              // Add error handling logic here
              // console.log("Error fetching user document:", error);
            }
          );
          return () => {
            unsubscribeFirestore();
          };
        } else {
          setIsAuthChecking(false);
        }
      },
      (error) => {
        // Add error handling logic here
        // console.log("Error in onAuthStateChanged:", error);
      }
    );

    return () => {
      unsubscribeAuth();
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        userData,
        userEmail,
        planId,
        isPaid,
        isAuthChecking,
        idToken,
        isNewlyLoggedIn, // Add this
        setIsNewlyLoggedIn, // And this
        treeMembership,
        treeAdminStatus,
        uid: user?.uid, // Add this
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
